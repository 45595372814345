.App {
  text-align: center;
}

.page-head {
  text-align: left;
}

.content {
  text-align: left;
  margin:  40px auto 0px;
}

.box {
  padding: 25px 50px;
  background-clip: border-box;
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgba(0, 0, 0, 0.176);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(0, 0, 0, 0.176);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.176);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.176);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);

}

.content-column {
  min-width:220px;
  white-space: nowrap;
}

.bio-pic {
  float:right;
  margin-left:25px;
  margin-bottom: 25px;
}

.page-image {
  float:right;
  width:33%;
  max-width:600px;
  min-width:180px;
  margin-left:25px;
  margin-bottom:25px;
  clear:both;
}

.home-preview-image {
  object-fit:scale-down;
}
.blog-preview-image {
  object-fit:fill;
}

.indent {
  margin:0px 25px 25px 25px;

}
.indent li {
  margin-bottom: 10px;
}

.nowrap {
  white-space: nowrap;
}

.company-name {
  font-size: 48px;
  font-family: 'The Nautigal', cursive;
  font-weight: bolder;
  text-align: left;
  line-height:40px;
  margin:0px;
}

.company-name-sub {
  font-size: 24px;
  font-family: 'Alegreya Sans SC', sans-serif;
  letter-spacing: 5px;
  line-height:20px;
  margin:0px;
  text-align: center;
}

body {
  background-color: #EBEDEF;
}

.greeting {
  color:darkslateblue;
  font-family: 'Adamina', serif;
  margin-bottom:20px;
}

div.greeting {
  font-size:28pt;
}
p.greeting {
  font-size:16pt;
}

form {
  font-size:11pt;
}

form .required::after {
  color: red;
  content: " *"
}
#contact-form div.col,div.col-2 {
  margin-bottom: 30px !important;
}

.checkbox-group {
  display: inline-block;
  margin-left: 20px;
}

.for-sale-table {
  margin-bottom: 20px;
}
.sold-overlay {
  position: absolute;
  top: 20%;
  left: 5%;
  transform: translate(-50%, -50%);
  color:orangered;
}
.sold > td {
  background-color: lightslategray !important;
}
.sold > td:first-child {
  position: relative;
  font-size: 48pt;
  text-shadow: 2px 2px 6px black;
  font-family: 'Abril Fatface', cursive;
}
.sold > td:first-child >div {
  transform: rotate(-10deg);
}
.for-sale-table Col {
  padding:10px;
}

h1 {
  font-family: 'Staatliches', sans-serif;
  margin-bottom: 35px;
}
h2 {
  color:black;
}
h3 {
  /*font-size:26pt;*/
  font-weight: bold;
}
h4 {
  /*font-size:18pt;*/
}
div.card {
  margin-bottom:20px;
}

.list-wrapper {
  max-width: 600px;
  min-height: 142px;
  height: 3vw;
  margin: 20px auto;
}
.list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-items: stretch;
  column-gap:15px;
}
.list div {
  white-space: nowrap;
  text-align: left;
  font-size:10pt;
  width: 33%;
  flex-basis: 20px;
}
.list div::before {
  content: "• "
}

.goat-utility-table {
  margin: 0px 50px 50px 0px;
}
.goat-utility-table th {
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
  text-align: right;
}
.goat-utility-table td {
  padding: 10px;
  vertical-align: top;
}

.home {
  text-align: center;
}

.home-logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: -6%;
  top: -200px;
}

.home-company-name {
  text-align: center;
  font-family: 'The Nautigal', cursive;
  font-weight: bolder;
  font-size:10vw;
  line-height:9vw;
  margin:0px;
  color:white;
  text-shadow: 2px 2px 6px black;
  text-align: center;
}

.home-company-name-sub {
  font-size: 4vw;
  line-height:3vw;
  font-family: 'Alegreya Sans SC', sans-serif;
  letter-spacing: 15px;
  margin:0px;
  color:white;
  text-shadow: 2px 2px 6px black;
  text-align: center;
}

.home-image {
  object-fit: cover;
  width:100%;
  max-height:450px;
  object-position: center middle;
  /*background-size: contain;*/
  /*background-repeat: no-repeat;*/
  /*background-position: center;*/
  /*clip-path: rectangle(0px, 0px, 100%, 400px,0,0);*/
}

.home-links {
  margin: 50px auto;
}
.home-links a {
  margin: 0px 20px;
  color: deepskyblue;
  text-decoration: none;
  font-size: 2vw;
}

.footer {
  text-align: center;
  color: white;
}
.facebook {
  font-size: 36px;
  color: dodgerblue;
}
.instagram {
  font-size: 36px;
  color: mediumvioletred;
}